import { render, staticRenderFns } from "./Regulators.vue?vue&type=template&id=26be28de&scoped=true&"
import script from "./Regulators.vue?vue&type=script&lang=ts&"
export * from "./Regulators.vue?vue&type=script&lang=ts&"
import style0 from "./Regulators.vue?vue&type=style&index=0&id=26be28de&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26be28de",
  null
  
)

export default component.exports