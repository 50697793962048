var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex',[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',{attrs:{"id":"header-title"}},[_vm._v("Regulators")]),_c('v-spacer'),_c('v-btn',{attrs:{"data-create-regulator":"","fab":"","large":"","icon":"","to":_vm.newRegulatorRoute}},[_c('v-icon',[_vm._v("add_circle_outline")])],1)],1),(_vm.currentUser)?_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"rows-per-page-items":[
          20,
          50,
          { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 } ],"pagination":_vm.pagination,"headers":_vm.headers,"loading":_vm.loading,"items":_vm.regulators},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticStyle:{"width":"100%"},attrs:{"color":"primary","indeterminate":""}})]},proxy:true},{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
                'column',
                { desc: _vm.pagination.descending},
                { asc: !_vm.pagination.descending},
                { active: header.value === _vm.pagination.sortBy},
                { sortable: header.sortable } ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[(header.sortable)?_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]):_vm._e(),_vm._v(" "+_vm._s(header.text)+" ")],1)}),0)]}},{key:"items",fn:function(ref){
              var user = ref.item;
return [_c('tr',{staticClass:"one-row"},[_c('td',{attrs:{"data-regulator-name":""}},[_vm._v(_vm._s(user.NAME))]),_c('td',{attrs:{"data-regulator-email":""}},[_vm._v(_vm._s(user.EMAIL))]),_c('td',{staticClass:"px-2 actions",class:{ loading: _vm.loading }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"data-edit-regulator-icon":"","color":"grey darken-1"},on:{"click":function($event){return _vm.editStoreOwner(user.UID)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1 pointer",attrs:{"color":"grey darken-1"},on:{"click":function($event){return _vm.signInToPlatformWithUser(user)}}},on),[_vm._v(" person ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('sign_in_with_user'))+" ")])],1)])]}}],null,false,500136942)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }